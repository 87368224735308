

//---------------------------
//ヘッダーのディレクトリ判定
//---------------------------
let loc = window.location.pathname;
// let dir = loc.substring(0, loc.lastIndexOf('/'));

if (loc.match(/about-us/)) {
  $("#about-us").toggleClass("on");
}
else if(loc.match(/services/)){
  $("#services").toggleClass("on");
}
else if(loc.match(/works/)){
  $("#works").toggleClass("on");
} 
else if(loc.match(/medialabs-lab/)){
  $("#medialabs-lab").toggleClass("on");
}
else if(loc.match(/company/)){
  $("#company").toggleClass("on");
}
else if(loc.match(/topics/)){
  $("#topics").toggleClass("on");
}
else if(loc.match(/recruitment/)){
  $("#recruitment").toggleClass("on");
} 
