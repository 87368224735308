const accordion = (() => {
  return class Accordion {
    constructor(
      rootElement = ".js-accordion",
      options
    ) {
      this.accordionRootElements = this.getElements(rootElement);
      if (!this.accordionRootElements.length) return;

      const defaultOptions = {
        triggers: [],
        panels: [],
        trigger: ".js-accordion-trigger",
        panel: ".js-accordion-panel",
        easing: "ease-out",
        duration: '.3s',
        multipleOpen: true,
        defaultOpenPanels: [],
        onOpen: () => { },
        onClose: () => { }
      }

      this.options = this.mergeOptions(defaultOptions, options);
      this.initialized = false;
      this.triggerEvents = [];
      this.windowResizeEvent = null;
      this.transitionendEvent = null;
      this.expanded = new Set();

      this.init();
    }

    getElements(selector) {
      if (selector instanceof HTMLElement) return [selector];
      if (selector instanceof jQuery) return [selector[0]];
      return Array.from(document.querySelectorAll(selector));
    }

    init() {
      if (this.initialized) return;
      this.setUpAttribute(this.options.triggers, this.options.panels);
      this.removeEvents();
      this.triggerEvents = this.registerTriggerEvents();
      this.windowResizeEvent = this.registerResizeEvent();
      this.initialized = true;
    }

    registerTriggerEvents() {
      let register = [];
      this.options.triggers.forEach((trigger, index) => {
        register.push(this.attachEvent(trigger, 'click', this.triggerClick.bind(this, trigger)));
        register[index].addEvent();
      });
      return register;
    }

    registerResizeEvent() {
      let register = this.attachEvent(window, 'resize', this.windowResizePanelHeightRecalculation.bind(this));
      register.addEvent();
      return register;
    }

    removeEvents() {
      if (!this.triggerEvents.length || !this.windowResizeEvent) return;

      this.triggerEvents.forEach((trigger) => {
        trigger.removeEvent();
      });
      this.windowResizeEvent.removeEvent();

      this.triggerEvents = [];
      this.windowResizeEvent = null;
    }

    mergeOptions(defaultOptions, options) {
      const mergeOptions = Object.assign({}, defaultOptions, options || {});
      mergeOptions.triggers = [];
      mergeOptions.panels = [];
      
      this.accordionRootElements.forEach(root => {
        const triggers = Array.from(root.querySelectorAll(mergeOptions.trigger));
        const panels = Array.from(root.querySelectorAll(mergeOptions.panel));
        mergeOptions.triggers.push(...triggers);
        mergeOptions.panels.push(...panels);
      });

      return mergeOptions;
    }

    setUpAttribute(triggers, panels) {
      const randomId = Math.random().toString(36).slice(2);
      const pinpointOpen = [];
      const pinpointClose = [];

      triggers.forEach((trigger, index) => {
        trigger.setAttribute('id', `accordion-trigger-${randomId}-${index}`);
        trigger.setAttribute('aria-expanded', "false");
        trigger.setAttribute('aria-controls', `accordion-panel-${randomId}-${index}`);
      });

      panels.forEach((panel, index) => {
        panel.setAttribute('id', `accordion-panel-${randomId}-${index}`);
        panel.setAttribute('aria-hidden', "true");
        panel.style.boxSizing = 'border-box';
        panel.style.overflow = 'hidden';
        panel.style.height = '0px';
        if (panel.hasAttribute("data-accordion-default-open")) {
          pinpointOpen.push(index);
        }
        if (panel.hasAttribute("data-accordion-default-close")) {
          pinpointClose.push(index);
        }
      });

      this.options.defaultOpenPanels.forEach((index) => {
        this.defaultOpenPanel(index);
      });

      pinpointOpen.forEach((index) => {
        this.pinpointOpenPanel(index);
      });
      pinpointClose.forEach((index) => {
        this.pinpointClosePanel(index);
      });
    }

    defaultOpenPanel(index) {
      const trigger = this.options.triggers[index];
      const panel = this.options.panels[index];
      this.panelOpen(trigger, panel);
    }

    pinpointOpenPanel(index) {
      const trigger = this.options.triggers[index];
      const panel = this.options.panels[index];
      this.panelOpen(trigger, panel);
    }

    pinpointClosePanel(index) {
      const trigger = this.options.triggers[index];
      const panel = this.options.panels[index];
      this.panelClose(trigger, panel);
    }

    triggerClick(trigger, e) {
      e.preventDefault();
      const panel = document.querySelector(`#${trigger.getAttribute('aria-controls')}`);

      if (!this.options.multipleOpen) {
        this.expanded.forEach((index) => {
          if (!this.expanded.has(this.getItemIndex(trigger))) {
            this.otherPanelClose(index);
          }
        });
      }

      if (trigger.getAttribute('aria-expanded') == "false") {
        this.panelOpen(trigger, panel, true, e);
      } else {
        this.panelClose(trigger, panel, e);
      }
    }

    panelOpen(trigger, panel, notTransition, event) {
      trigger.setAttribute('aria-expanded', "true");
      panel.setAttribute('aria-hidden', "false");
      panel.style.height = `${this.getPanelHeight(panel)}px`;
      panel.style.visibility = 'visible';
      panel.style.transition = notTransition ? `height ${this.options.easing} ${this.options.duration}, visibility ${this.options.duration}` : "";
      this.expanded.add(this.getItemIndex(trigger));

      if (event) {
        if (this.options.duration == 0) {
          this.onOpen(trigger, panel);
        } else {
          this.transitionendEvent = this.attachEvent(panel, 'transitionend', this.onOpen.bind(this, trigger, panel));
          this.transitionendEvent.addEvent();
        }
      }
    }

    onOpen(trigger, panel) {
      this.options.onOpen(trigger, panel);
      if (!(this.options.duration == 0)) {
        this.transitionendEvent.removeEvent();
      }
    }

    panelClose(trigger, panel, event) {
      trigger.setAttribute('aria-expanded', "false");
      panel.setAttribute('aria-hidden', "true");
      panel.style.height = "0px";
      panel.style.visibility = 'hidden';
      panel.style.transition = `height ${this.options.easing} ${this.options.duration}, visibility ${this.options.duration}`;
      this.expanded.delete(this.getItemIndex(trigger));

      if (event) {
        if (this.options.duration == 0) {
          this.onClose(trigger, panel);
        } else {
          this.transitionendEvent = this.attachEvent(panel, 'transitionend', this.onClose.bind(this, trigger, panel));
          this.transitionendEvent.addEvent();
        }
      }
    }

    onClose(trigger, panel) {
      this.options.onClose(trigger, panel);
      if (!(this.options.duration == 0)) {
        this.transitionendEvent.removeEvent();
      }
    }

    otherPanelClose(index) {
      const trigger = this.options.triggers[index];
      const panel = this.options.panels[index];
      this.panelClose(trigger, panel);
    }

    getItemIndex(trigger) {
      return this.options.triggers.indexOf(trigger);
    }

    getPanelHeight(panel) {
      let ghostPanel = panel.cloneNode(true);
      panel.parentNode.appendChild(ghostPanel);
      ghostPanel.style.cssText = "display:block; height:auto; visibility:hidden; position: absolute; width: " + panel.getBoundingClientRect().width + "px;";
      const styles = window.getComputedStyle(panel);
      const margin = parseFloat(styles.marginTop) + parseFloat(styles.marginBottom);
      const padding = parseFloat(styles.paddingTop) + parseFloat(styles.paddingBottom);
      const border = parseFloat(styles.borderTopWidth) + parseFloat(styles.borderBottomWidth);
      
      const ghostPanelHeight = ghostPanel.offsetHeight + margin + padding + border;
      panel.parentNode.removeChild(ghostPanel);
      return Math.ceil(ghostPanelHeight); // 端数を切り上げて確実に全体を表示
    }

    windowResizePanelHeightRecalculation() {
      this.expanded.forEach((index) => {
        const panel = this.options.panels[index];
        const resizedHeight = this.getPanelHeight(panel);
        panel.style.height = resizedHeight + 'px';
      });
    }

    destroy() {
      if (this.initialized) {
        this.triggerEvents.forEach(trigger => {
          trigger.removeEvent();
        });
        this.triggerEvents = [];
        this.windowResizeEvent.removeEvent();
        this.windowResizeEvent = null;

        this.options.triggers.forEach((v, i) => {
          const trigger = this.options.triggers[i];
          const panel = this.options.panels[i];
          this.panelOpen(trigger, panel, false);
          panel.removeAttribute("style");
        });

        this.expanded = new Set();
        this.initialized = false;
      }
    }

    attachEvent(element, type, listener, options) {
      return {
        addEvent() {
          element.addEventListener(type, listener, options);
        },
        removeEvent() {
          element.removeEventListener(type, listener);
        }
      };
    }
  }

  function convertElement(obj) {
    if (obj instanceof HTMLElement) {
      return obj;
    }
    if (obj instanceof jQuery) {
      return obj[0];
    }
    return document.querySelector(obj);
  }
})();

export default accordion;