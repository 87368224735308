import swiper from 'swiper';


/*---------------------------
スライド
-----------------------------*/

var loopSwiper = new swiper('.js-swiper', {
	loop: false,
	slidesPerView: 2.8,
	spaceBetween: 30,
	speed: 1000,
	navigation: {
		nextEl: '.top-works .p-top-slide__next',
		prevEl: '.top-works .p-top-slide__prev',
	},
	breakpoints: {
		599: {
			slidesPerView: 1.2,
			spaceBetween: 15,
		}
	}
});


var loopSwiper2 = new swiper('.js-swiper2', {
	loop: false,
	slidesPerView: 2.8,
	spaceBetween: 30,
	speed: 1000,
	navigation: {
		nextEl: '.top-blog .p-top-slide__next',
		prevEl: '.top-blog .p-top-slide__prev',
	},
	breakpoints: {
		599: {
			slidesPerView: 1.2,
			spaceBetween: 15,
		}
	}
});

var loopSwiper3 = new swiper('.js-swiper3', {
	autoplay: {
		delay: 2000,
	},
	loop: true,
	loopAdditionalSlides: 1,
	//slidesPerView: 1.1,
	spaceBetween: 70,
	speed: 1000,
	pagination: {
		el: ".js-swiper3 .swiper-pagination",
		clickable: true,
	},
	// navigation: {
	// 	nextEl: '.p-works-slide__next',
	// 	prevEl: '.p-works-slide__prev',
	// },
	breakpoints: {
		599: {
			slidesPerView: 1.1,
		}
	}
});








window.addEventListener('load', () => {
	const slideItems = Array.from(document.querySelectorAll('.p-works-service-example-swiper-items'));

	if (slideItems) {
		if (slideItems.length >= 5) {
			const loopSwiper4 = new swiper('.js-swiper4', {
				autoplay: {
					delay: 2000,
				},
				loop: true,
				loopAdditionalSlides: 1,
				slidesPerView: 4,
				spaceBetween: 30,
				speed: 1000,
				navigation: {
					prevEl: '.p-works-service-example-swiper-next',
					nextEl: '.p-works-service-example-swiper-prev',
				},
				pagination: {
					el: ".js-swiper4 .swiper-pagination",
					clickable: true,
				},
				breakpoints: {
					768: {
						slidesPerView: 3,
					},
					599: {
						slidesPerView: 1.8,
						spaceBetween: 15,
						centeredSlides: true,
					},
					390: {
						slidesPerView: 1.2,
						centeredSlides: true,
					}
				},
			});
		} else {

			const swiperNextprevBtn = document.querySelector('.p-works-service-example-swiper-button');
			if(swiperNextprevBtn){
				swiperNextprevBtn.style.display = 'none';
			}

			const loopSwiper5 = new swiper('.js-swiper4', {
				// autoplay: {
				// 	delay: 2000,
				// },
			
				loop: false,
				// loopAdditionalSlides: 1,
				slidesPerView: 4,
				spaceBetween: 30,
				speed: 1000,
				// navigation: {
				// 	prevEl: '.p-works-service-example-swiper-next',
				// 	nextEl: '.p-works-service-example-swiper-prev',
				// },
				pagination: {
					el: ".swiper-pagination",
					clickable: true,
				},
				breakpoints: {
					768: {
						slidesPerView: 3,
					},
					599: {
						slidesPerView: 1.8,
						spaceBetween: 15,
						centeredSlides: true,
					},
					390: {
						slidesPerView: 1.2,
						centeredSlides: true,
					}
				},
			});
			
		}


	}


})

