document.addEventListener('DOMContentLoaded', () => {
  const options = {
    root: null,
    rootMargin: '50px', // より早めに検知開始
    threshold: 0.1 // 少しでも見えたら開始
  };

  const observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        // ランダムな遅延を追加してより自然な表示に
        const delay = 100 + Math.random() * 200;
        setTimeout(() => {
          entry.target.classList.add('is-visible');
        }, entry.target.dataset.index * 100 + delay);
        observer.unobserve(entry.target);
      }
    });
  }, options);

  // インデックスを付与して監視開始
  document.querySelectorAll('.data-card__item').forEach((item, index) => {
    item.dataset.index = index;
    observer.observe(item);
  });
});