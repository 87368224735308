import Accordion from '../../plugins/accordion.js';
import isMobile from "../../plugins/is-mobile.js";
const accordion = new Accordion(".js-accordion");


const elements = document.querySelectorAll(".js-accordion");



function test(trigger, panel){
  trigger.style.color = '#f00'
}
function fuga(trigger, panel){
  trigger.style.color = '#000'
  
}

