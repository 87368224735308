document.addEventListener('DOMContentLoaded', function () {
  const navItems = document.querySelectorAll('.c-sideNav-list a'); // navのリストアイテム
  const sections = Array.from(navItems).map(item => document.querySelector(item.getAttribute('href'))); // 各セクションのidを取得
  const offset = 100; // 発火させる位置を固定（px単位で調整）

  // 現在のスクロール位置に応じてナビのis-activeクラスを制御する関数
  function updateNav() {
    let currentIndex = -1;

    sections.forEach((section, index) => {
      const rect = section.getBoundingClientRect();
      
      if (rect.top - offset <= 0) {
        currentIndex = index;
      }
    });

    // 現在のスクロール位置に応じてis-activeクラスを追加/削除
    navItems.forEach((item, index) => {
      if (index === currentIndex) {
        item.parentElement.classList.add('is-active');
      } else {
        item.parentElement.classList.remove('is-active');
      }
    });
  }

  // スクロールイベントを監視する関数を定義
  function handleScrollEvent() {
    if (window.innerWidth > 768) {
      window.addEventListener('scroll', updateNav);
      updateNav(); // 初回ページ読み込み時にもナビのクラスを更新
    } else {
      window.removeEventListener('scroll', updateNav); // 768px以下の時はscrollイベントを削除
      navItems.forEach(item => item.parentElement.classList.remove('is-active')); // is-activeを削除
    }
  }

  // ページロード時とリサイズ時にチェック
  window.addEventListener('resize', handleScrollEvent);
  handleScrollEvent(); // 初回チェック
});
