document.addEventListener('DOMContentLoaded', function() {
  // 監視対象の要素
  const section = document.querySelector('.top-contents');
  
  // 対象要素が存在しない場合は処理を終了
  if (!section) {
    return;
  }
  
  const leftSide = document.querySelector('.top-contents-leftSide');
  const rightSide = document.querySelector('.top-contents-rightSide');
  const imageElements = document.querySelectorAll('.top-contents__image__inner');
  
  // 必要な要素が存在しない場合も処理を終了
  if (!leftSide || !rightSide || imageElements.length === 0) {
    return;
  }
  
  // ローカルストレージからアニメーション表示履歴を確認
  const hasSeenAnimation = localStorage.getItem('hasSeenContentAnimation');
  
  // URLにforce_animationパラメータがある場合や、スーパーリロードの場合は強制的にアニメーション実行
  const urlParams = new URLSearchParams(window.location.search);
  const forceAnimation = urlParams.has('force_animation');
  
  // すでにアニメーションを見たことがあり、強制表示フラグがない場合
  if (hasSeenAnimation && !forceAnimation) {
    
    // --image01から--image05には直接animateクラスを付与
    imageElements.forEach(el => {
      if (!el.classList.contains('--image06') && !el.classList.contains('--image07')) {
        el.classList.add('animate');
        el.style.transition = 'none'; // アニメーションをスキップ
      }
    });
    
    // 左右のコンテンツにも直接最終状態を適用
    leftSide.classList.add('animate');
    leftSide.style.transition = 'none';
    rightSide.classList.add('animate');
    rightSide.style.transition = 'none';
    
    // 少し遅らせて--image06と--image07のアニメーションを開始
    setTimeout(() => {
      // --image06と--image07のアニメーションのみ通常通り実行
      const rotatingElements = document.querySelectorAll('.--image06, .--image07');
      if (rotatingElements.length > 0) {
        rotatingElements.forEach(el => {
          el.classList.add('animate');
        });
      }
      
      // トランジションを元に戻す（今後のためにリセット）
      setTimeout(() => {
        imageElements.forEach(el => {
          el.style.transition = '';
        });
        leftSide.style.transition = '';
        rightSide.style.transition = '';
      }, 100);
    }, 100);
  } else {
    
    // Intersection Observerの設定
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    };
    
    // コールバック関数
    const callback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // セクションが表示されたら、即座にアニメーション開始
          imageElements.forEach(el => {
            el.classList.add('animate');
          });
          
          // 左側コンテンツの表示タイミングを早める
          setTimeout(() => {
            leftSide.classList.add('animate');
            rightSide.classList.add('animate');
            
            // アニメーション完了後にローカルストレージに記録
            setTimeout(() => {
              localStorage.setItem('hasSeenContentAnimation', 'true');
            }, 1000);
          }, 2800);
          
          observer.unobserve(entry.target);
        }
      });
    };
    
    const observer = new IntersectionObserver(callback, options);
    observer.observe(section);
  }
});